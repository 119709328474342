import * as React from "react";
import { useEffect } from "react";

import Layout from "../components/common/layout";
import Seo from "../components/common/seo";

const CareMembership = () => {
  useEffect(() => {
    const injectScript = () => {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://pawblisher.care.vet/assets/js/embed.js";

      window.ottoCareMicrosite = { slug: "canton-animal-hospital" };

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    };

    const addHtmlClass = () => {
      const htmlElement = document.documentElement;
      htmlElement.classList.add("careMembershipPage");
    };

    injectScript();
    addHtmlClass();
  }, []);

  return (
    <Layout>
      <Seo title="Care Membership Dashboard" />
      <div id="otto-care-microsite-container">
        {/* Content will be dynamically injected by the script */}
      </div>
      </Layout>
  );
};

export default CareMembership;